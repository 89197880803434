/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage'; // Import the LandingPage component
import DemoPage from './DemoPage';
import Bar from './Bar';
import SubscriptionsPage from './SubscriptionsPage';
import UserProfile from './UserProfile';
import AboutPage from './AboutPage';


// Import other components for additional pages if needed
import MainConsole from './MainConsole';
import {AuthProvider} from './AuthContext';
import { Helmet } from 'react-helmet'; 

function App() {
  // const [setUser] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false); 

  return (

    <Router>
      <AuthProvider> {/* Wraps the entire Router in AuthProvider */}
      <div className="App">
        <Helmet>
              {/* Google Tag (gtag.js) - Replace placeholder ID */}
              <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16462602131">
              </script>
              <script>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'AW-16462602131');
                `}
              </script>
          </Helmet>

        {/* Define routes for different pages */}
        <Bar isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
          <Routes>
            {/* Main Console Route */}
            <Route path="/console" element={<MainConsole isModalOpen={isModalOpen} setModalOpen={setModalOpen}/>} />
            {/* Landing page route */}
            <Route path="/" element={<LandingPage isModalOpen={isModalOpen} setModalOpen={setModalOpen}/>} />
            {/* Demo Route */}
            <Route path="/demo" element={<DemoPage />} />
            {/* Subscriptions Routes */}
            <Route path="/subscriptions" element={<SubscriptionsPage isModalOpen={isModalOpen} setModalOpen={setModalOpen}/>} />
            {/* User Profile Route */}
            <Route path="/profile" element={<UserProfile />} />
            {/* User Profile Route */}
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </Bar>
      </div>
      </AuthProvider>
    </Router>
    
  );
}

export default App;

